.bg-yellow {
  background-color: #ffaa01;
}

/*Login*/

.form-inner {
  padding: 30px;
}

.form-logo {
  text-align: center;
  margin-bottom: 25px;
}

.form-line {
  position: relative;
  display: block;
  width: 60px;
  height: 5px;
  background-color: #ffa300;
  border-radius: 99px;
  margin: 0 auto 15px auto;
}

.form-heading {
  font-size: 20px;
  text-align: center;
  color: #183459;

}

.form-select-button {
  position: relative;
  width: 50%;
  margin: 0 auto;
}

.form-select-button select {
  appearance: none;
  height: 49px;
  border-radius: 10px;
  border: 1px solid #faa21d;
  padding: 13px 30px 13px 15px;
  display: block;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
}

.form-select-button .form-select-option {
  font-weight: 700;
}

.form-select-button i {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .form-select-button {
    width: 80%;
  }
}

.form-radio-button {
  margin-left: -7.5px;
  margin-right: -7.5px;
  display: flex;
  align-items: center;
}

.form-radio-button .form-radio-wrap {
  height: 50px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  width: 50%;
  position: relative;
}

.form-radio-button .form-radio-wrap input[type='radio'] {
  width: 20px;
  height: 20px;
  border-color: #e8e8e8;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.form-radio-button .form-radio-wrap label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  padding: 15px 10px 15px 44px;
  border: 1px solid #e8e8e8;
  border-radius: 99px;
  cursor: pointer;
}

.form-body .form-group {
  position: relative;
  margin-bottom: 15px;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
}

.css-yk16xz-control {
  height: 50px !important;
  margin: 0px 0px 0px 48px !important;
  box-shadow: inset 3px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  cursor: pointer;
}

.css-2b097c-container {
  height: 50px !important;
}

.css-1pahdxg-control {
  height: 50px !important;
  margin: 0px 0px 0px 48px !important;
  box-shadow: inset 3px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  cursor: pointer;
}

.css-2613qy-menu {
  width: 300px !important;
  margin: 0px 0px 0px 48px !important;
  box-shadow: inset 3px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  cursor: pointer;
}

.shadow-lg {
  -webkit-box-shadow: 0 10px 15px -3px rgb(113 126 195 / 10%), 0 4px 6px -4px rgb(113 126 195 / 10%) !important;
  box-shadow: 0 10px 15px -3px rgb(113 126 195 / 10%), 0 4px 6px -4px rgb(113 126 195 / 10%) !important;
}

.bg-add {
  background-color: #abf3b56e;
}

.text-add {
  color: #48b842;
}

.icon-school {
  width: 50px;
  height: 50px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.icon-school img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #ffffff;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
  padding: 11px !important;
} */

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 50px !important;
} */

.ant-select-selection-placeholder {
  color: hsl(0, 0%, 50%);
}

/* .ant-select-dropdown {
  width: 500px !important;
} */

.site-card-border-less-wrapper {
  padding: 30px;
}

.container-template {
  display: contents;
}

.itable tbody .container-template tr:last-child td:first-child,
.itable tbody .container-template tr:last-child td:last-child {
  border-radius: 0px;
}

.itable tbody .container-template:last-child tr:last-child td:first-child {
  border-bottom-left-radius: 10px !important;
}

.itable tbody .container-template:last-child tr:last-child td:last-child {
  border-bottom-right-radius: 10px !important;
}

.ant-card-head-wrapper {
  justify-content: flex-start !important;
  flex-direction: row-reverse !important;
  gap: 20px !important;
}

.ant-select-item {
  font-weight: 400 !important;
}

.h-50 {
  height: 50px !important;
}

.hover\:bg-45a:hover {
  background-color: #45aaf2 !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #45aaf2 !important;
}

.z-full {
  z-index: 100 !important;
}

.show-sidebar {
  width: 70px;
  height: 70px;
  text-align: center;
  justify-content: center;
  line-height: 70px;
}

button {
  border: none;
  background-color: transparent;
}

/* .ant-table-container table>thead>tr:first-child th:first-child {
    background: rgb(252, 243, 207);
} */

.ant-card-body {
  padding: 15px !important;
}

.remove {
  cursor: pointer;
}

.bg-ffc {
  background-color: #ccc;
}

.edit,
.remove,
.detail,
.clone {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
}

.edit:hover,
.remove:hover,
.detail:hover,
.clone:hover {
  transform: scale(1.3);
}

.bg-success {
  background-color: #48b842;
}

.hover-bg-success:hover {
  background-color: #48b842;
  border: none;
}

.bg-primary {
  background-color: #41a9ff;
}

.hover-bg-primary:hover {
  background-color: #41a9ff;
  border: none;
}

.border-primary {
  border-color: #41a9ff;
}

.text-primary {
  color: #41a9ff;
}

.border-print {
  border-color: #13c2c2;
}

.text-print {
  color: #13c2c2;
}

.hover-bg-print:hover {
  background-color: #13c2c2;
}

.hover-text-print:hover {
  color: white;
}

textarea:focus::placeholder {
  color: transparent;
}

.clone {
  color: #b37feb;
}

.wrap-choose-school .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

button:disabled,
button:disabled:hover {
  background: #ccc;
  color: #7a7a7a;
  border: none;
}

.ant__btn__download:hover {
  background: rgb(107 209 101) !important;
  border-color: rgb(107 209 101) !important;
}

.align-center {
  align-items: center;
}

.color-primary {
  color: #1890ff;
}

.color-edit {
  color: rgb(255, 197, 61);
}

.color-change {
  color: #48b842;
}

.btn-add {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}

.btn-add:hover {
  border-color: #40a9ff;
  background: #40a9ff;
}

.text-error {
  color: red;
}

.text-err-login {
  padding-left: 15px;
}

.ant-button-active {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
}

.text-wrap {
  word-break: break-word;
}

/* CUSTOMIZE SIDEBAR */

.container-scroller {
  overflow: hidden;
  display: flex;
  position: relative;
}

.layout__wrap {
  flex: 1 1 auto;
  width: 100%;
}

.sidebar__customize {
  top: 80px;
  min-height: calc(100vh - 70px);
  font-weight: 400;
  padding: 0;
  z-index: 11;
}

.sidebar__customize {
  min-height: calc(100vh - 70px);
  background: #0d5cba;
  font-weight: normal;
  width: 278px;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}

/* width */
.sidebar__customize::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

/* Track */
.sidebar__customize::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

/* Handle */
.sidebar__customize::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar__customize::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar__customize .sidebar-brand-wrapper {
  height: 70px;
  background: #051f3e;
  width: 70px;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 900;
}

.d-none {
  display: none !important;
}

nav {
  display: block;
}

.sidebar-brand {
  background-color: #fff;
  font-size: 1.5rem;
  margin: 0;
  width: 210px;
  min-width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 0.25s ease; */
}

.sidebar__customize .sidebar-brand-wrapper .sidebar-brand img {
  /* width: 124px; */
  /* max-width: 100%; */
  /* height: 28px; */
  margin: auto;
  vertical-align: middle;
}

.sidebar__customize .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini {
  display: none;
}

.sidebar__customize .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
  margin: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sidebar__customize .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 60px;
}

.container-fluid {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: 278px;
}

.page-body-wrapper {
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  width: calc(100% - 278px);
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
}

.navbar {
  font-weight: 400;
  background: #fff;
  left: 70px;
  transition: left 0.25s ease;
  -webkit-transition: left 0.25s ease;
  -moz-transition: left 0.25s ease;
  -ms-transition: left 0.25s ease;
}

.flex-row {
  flex-direction: row !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-column {
  flex-direction: column;
}

.d-jc-between {
  justify-content: space-between;
}

.d-jc-start {
  justify-content: start;
}

.teacher-info {
  align-items: center;
}

.navbar .navbar-menu-wrapper {
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  -webkit-box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  -moz-box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  color: #fff;
  padding-right: 15px;
  width: 100%;
  min-height: 80px;
}

.flex-grow {
  flex-grow: 1;
}

.align-items-stretch {
  align-items: stretch !important;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #fff;
  /* border: 1px solid transparent; */
  /* border-radius: 0.25rem; */
  width: 80px;
  color: #051f3e;
}

.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
  font-size: 1.2rem;
}

.navbar .navbar-menu-wrapper .navbar-toggler {
  border: 0;
  color: #051f3e;
  height: 70px;
  border-radius: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav {
  flex-direction: row;
  align-items: center;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 0.9375rem;
  margin-left: 1rem;
  margin-right: 1rem;
  white-space: nowrap;
  line-height: 1;
}

.sidebar__customize .nav .nav-item {
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
  /* background-color: #b7b7b8; */
  /* padding: 15px 20px 15px 15px; */
  cursor: pointer;
}

.sub-menu-drop {
  background-color: rgba(255, 255, 255, 0.4);
}

.sub-menu-drop .nav-item {
  border-bottom: 0px solid rgb(255, 255, 255, 0.5) !important;
}

.sub-menu-drop .nav-item:first-child {
  border-top: 0px solid rgb(255, 255, 255, 0.5) !important;
}

.sub-menu-drop .nav-item .nav-link {
  /* color: #e7e5e5 !important; */
  padding-left: 50px !important;
}

.sidebar__customize .nav .nav-item.active .nav-link {
  color: #fff;
}

.sidebar__customize .nav .nav-item:hover {
  background-color: #0c56ad;
}

.sidebar__customize .nav .nav-item:hover {
  color: #fff;
}

.sidebar__customize .nav .nav-item .nav-link {
  /* border-bottom: 0.5px solid #bbb; */
  background-color: #0d5cba;
  color: #fff;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  height: 56px;
  padding: 0px 20px;
}

.sidebar__customize .nav .nav-item .nav-link:hover {
  background-color: #0c56ad;
  color: #fff !important;
}

#root > div.container-scroller > nav > ul > li.nav-item.menu-items.hover-open > a > span {
  width: 208px;
}

.active {
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
  background-color: #126edb !important;
  border-bottom-color: #f0a314;
  color: #fff !important;
}

.main-panel {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  left: 70px;
  min-height: 100vh;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
}

.sidebar-icon-only .content-wrapper {
  width: 100%;
}

.content-wrapper {
  padding: 1.875rem 1.75rem;
  /* width: calc(100% - 208px); */
  flex-grow: 1;
}

@media screen and (max-width: 1373px) {
  .w-200 {
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .sidebar-icon-only .sidebar__customize,
  .sidebar-icon-only .sidebar__customize .sidebar-brand-wrapper {
    width: 70px;
  }

  .sidebar-icon-only .sidebar__customize .sidebar-brand-wrapper .brand-logo {
    display: none;
  }

  .sidebar-icon-only .sidebar__customize .sidebar-brand-wrapper .brand-logo-mini {
    display: flex;
  }

  .sidebar-icon-only .main-panel {
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
  }

  .sidebar-icon-only .container-fluid {
    margin-left: 70px;
  }

  .sidebar-icon-only .container-fluid.page-body-wrapper {
    width: calc(100% - 70px);
  }

  .sidebar-icon-only .main-panel .navbar {
    left: 70px;
  }

  .sidebar-icon-only .sidebar__customize .nav {
    overflow: visible;
  }

  .sidebar-icon-only .sidebar__customize .nav .nav-item {
    position: relative;
    padding: 0;
  }

  .sidebar-icon-only .sidebar__customize .nav .nav-item .nav-link {
    text-align: center;
  }

  .sidebar-icon-only .sidebar__customize .nav .nav-item .nav-link .grow,
  .grow-close {
    display: none;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  /* .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  } */

  .sidebar-icon-only .sidebar__customize .nav .nav-item.hover-open .nav-link .grow {
    display: flex;
    align-items: center;
    background: #042954;
    padding: 0.5rem 1.4rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 260px;
    z-index: 1;
    line-height: 1.8;
  }

  .sidebar-icon-only .sidebar__customize .nav .nav-item.hover-open .nav-link .grow-close {
    display: flex;
    align-items: center;
    background: #042954;
    border: 1px solid #042954;
    padding: 0.5rem 1.4rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 208px;
    z-index: 1;
    line-height: 1.8;
  }

  .sidebar-icon-only .sidebar__customize .nav .nav-item.hover-open .nav-link {
    background: #042954;
  }
}

@media (max-width: 1200px) {
  .page-body-wrapper {
    width: 100%;
  }

  .container-fluid {
    margin-left: 0px;
  }

  .navbar {
    left: 0;
  }

  .main-panel {
    left: 0;
  }

  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }
}

@media screen and (max-width: 1199px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 70px);
    top: 70px;
    bottom: 0;
    overflow: auto;
    left: -300px;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  .sidebar-offcanvas.active {
    left: 0;
    background-color: #051f3e !important;
  }

  .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-hidden) {
    display: none;
  }

  .brand-logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
}

.modal__file span {
  text-align: center;
}

.sub-menu li {
  padding-left: 30px;
}

.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 15%);
}

.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%);
}

.nav-item.dropdown > .sub-menu-drop {
  display: none;
  transition: all 0.5s linear;
}

.nav-item.dropdown.show__dropdown > .sub-menu-drop {
  display: flex;
}

.show__dropdown {
  /* border-bottom: 2px solid #bbb; */
  background-color: #0c56ad !important;
}

.nav-item.dropdown.show__dropdown .nav-link .fas.fa-chevron-left {
  transform: rotate(-90deg);
}

.nav-item.dropdown.show__dropdown .nav-link .fas.fa-chevron-left {
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.nav-item.dropdown.hover-open.show__menu .fas.fa-chevron-left {
  transform: rotate(-90deg);
}

.nav-item.dropdown.hover-open.show__menu .fas.fa-chevron-left {
  transition: transform 0.3s, -webkit-transform 0.3s;
}

#root > div.container-scroller > nav > ul > li.nav-item.menu-items.active.hover-open > a > span {
  width: 208px;
}

.nav-item-close {
  display: flex;
  width: 208px;
  margin: 0px;
  background-color: #051f3e;
  border: 1px solid #042954;
  font-weight: 400;
}

.nav-link-close {
  align-items: center;
  color: #bbbbbb;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 54px;
  padding: 0 20px;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  white-space: nowrap;
}

.nav-item-close:hover {
  background-color: #042954;
  border: 1px solid #042954;
}

.nav-link-close:hover {
  color: #fff !important;
}

.nav-item:hover {
  background-color: #b1b8c0;
}

.nav-item.dropdown.show__menu > .sub-menu-drop {
  position: fixed;
  left: 70px;
  display: block;
  background-color: transparent;
  width: 208px;
}

.nav-item.dropdown.show__menu > .sub-menu-drop:hover {
  background-color: #042954;
}

.footer-modal {
  margin-bottom: 0px;
}

.footer-modal .ant-form-item-control-input-content {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.ant-picker-input > input {
  color: #000;
}

.ant-select-disabled .ant-select-selection-item {
  color: #000;
}

.upload-file {
  border-right: 1px solid #d9d9d9;
}

.upload-file {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-file label {
  color: #fff;
  width: auto;
  height: auto;
  padding: 5px;
  font-size: 16px;
}

.upload-file input[type='file'] {
  font-size: 40px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.ant-picker .ant-picker-input input {
  color: #000;
}

.ant-input.ant-input-disabled,
.ant-picker.ant-input-disabled {
  color: #000;
  border: 1px solid #333333 !important;
}

.ant-modal-body .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.input-modal {
  border: 1px solid #333333;
}

.ant-modal-content {
  box-shadow: 0px 0px 0px 2px #1890ff !important;
}

.cursor {
  cursor: pointer;
}

.btn__zalo {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #b2dbfd7a;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.show__drop {
  display: block;
}
.box-support .ant-card-bordered {
  border: none;
}
.btn__download {
  text-align: center;
}
.btn__download i {
  margin-left: 10px;
}
.btn__download,
.btn__download i {
  color: #fff;
}
.btn__download:hover {
  color: #fff;
}
.suport_used {
  border-top: none !important;
}

select:disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0px;
}
.ant-table-expanded-row > .ant-table-cell {
  padding: 0px !important;
}
textarea {
  border-color: #cbcee8 !important;
}
textarea:focus-visible {
  border-color: #cbcee8 !important;
  outline: none;
}
.disabled-row {
  opacity: 0.4;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .btn-grad {
  font-size: 11px !important;
  line-height: 25px;
}
.btn__phone {
  font-size: 12px !important;
}
.contract-peple {
  font-size: 12px;
} */
.displayNone {
  display: none;
}
.banner-e_content {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.disable-click {
  opacity: 0.7;
  pointer-events: none;
}
