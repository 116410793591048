.ant-table-container table > thead > tr:first-child th:first-child {
  background: #fafafa;
}

/* .ant-table-cell .ant-table-row-expand-icon-cell,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-right: none;
} */
.uploadfile {
  position: relative;
}
.uploadfile input {
  opacity: 0;
  z-index: 10;
}
#file-upload-btn {
  position: absolute;
  top: 0px;
  z-index: 9;
}
