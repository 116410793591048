.button {
  font-size: 1vw;
  border: 2px solid none;
  border-radius: 5px;
  height: 32px;
  padding: 5px;
  transition: 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  width: 140px;
  background: #1890ff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

/* @media (min-width: 641px) {
  .button {
    font-size: 12px;
  }
} */

.button:hover {
  background: #40a9ff;
}

.sp-text {
  display: inline-block;
  margin-left: 3px;
  color: #fff;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-weight: 400;
}

.icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
}

/* .button:hover {
    width: 180px;
    background: #2196f3;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    transition: display 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.button:hover .sp-text {
    display: inline-block;
    margin-left: 3px;
    color: #fff;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.button:active {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
} */
