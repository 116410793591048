.ih-526.overflow-y-auto.scroll-style {
    width: 70vw;
    height: 80vh;
}


.modal-viewfile {
    height: fit-content !important;
}

