.agencies .grid-container__item1 {
  grid-column: 1/2;
}
.agencies .grid-container__item3 {
  grid-column: 3/5;
}
.agencies .grid-container__item4 {
  grid-column: 5/7;
  text-align: right;
}
.form-password {
  position: relative;
}
.icon-eye {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
}

.select-angencies .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
}
