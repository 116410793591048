@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?mz0u1q');
  src: url('../fonts/icomoon.eot?mz0u1q#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?mz0u1q') format('truetype'),
    url('../fonts/icomoon.woff?mz0u1q') format('woff'),
    url('../fonts/icomoon.svg?mz0u1q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

:root {
  --primary-color: #ffa300;
  --success-color: #ffedcc;
  --color-red: #f46a6a;
  --color-red-light: #fbdddd;

  --color-green: #28c76f;
  --color-green-light: #d4f4e2;

  --color-grey-1: #6e6b7b;
  --color-grey-2: #999;
  --color-grey-light-1: #f1f1f1;
  --color-grey-light-2: #e2e1e5;

  --color-blue: #7367f0;
  --color-blue-light: #e3e1fc;

  --gradient-from-1: #ffb900;
  --gradient-to-1: #ff8b00;

  --gradient-from-2: #ff363f;
  --gradient-to-2: #870000;

  --gradient-from-3: #0a9099;
  --gradient-to-3: #359915;

  --color-blue-2: #183459;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-folder-3:before {
  content: '\e918';
}

.icon-reply-arrow:before {
  content: '\e922';
}

.icon-upload-file:before {
  content: '\e923';
}

.icon-user-1:before {
  content: '\e924';
}

.icon-triangular-black-right-arrow:before {
  content: '\e921';
}

.icon-menu:before {
  content: '\e90b';
}

.icon-down-chevron:before {
  content: '\e90c';
}

.icon-eye:before {
  /* content: "\e920"; */
}

.icon-businessman:before {
  content: '\e90d';
}

.icon-cancel:before {
  content: '\e90e';
}

.icon-down-chevron1:before {
  content: '\e90f';
}

.icon-download:before {
  content: '\e910';
}

.icon-download-to-storage-drive:before {
  content: '\e911';
}

.icon-edit-1:before {
  content: '\e912';
}

.icon-folder-1:before {
  content: '\e913';
}

.icon-folder-2:before {
  content: '\e914';
}

.icon-interview:before {
  content: '\e915';
}

.icon-khung-menu-02:before {
  content: '\e916';
  color: #fff;
}

.icon-list:before {
  content: '\e917';
}

.icon-notification1:before {
  content: '\e919';
}

.icon-reply:before {
  content: '\e91a';
}

.icon-trash:before {
  content: '\e91b';
}

.icon-video-conference:before {
  content: '\e91c';
}

.icon-library:before {
  content: '\e91d';
}

.icon-video-marketing1:before {
  content: '\e91e';
}

.icon-write1:before {
  content: '\e91f';
}

.icon-bar-chart:before {
  content: '\e900';
}

.icon-book:before {
  content: '\e901';
}

.icon-folder:before {
  content: '\e902';
}

.icon-group:before {
  content: '\e903';
}

.icon-home:before {
  content: '\e904';
}

.icon-lecture:before {
  content: '\e905';
}

.icon-notification:before {
  content: '\e906';
}

.icon-open-book:before {
  content: '\e907';
}

.icon-presentation:before {
  content: '\e908';
}

.icon-video-marketing:before {
  content: '\e909';
}

.icon-write:before {
  content: '\e90a';
}

html,
table {
  font-size: 14px;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
}

select:focus-visible,
input:focus-visible {
  outline: none;
}

a:hover {
  color: #ffa300;
  text-decoration: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

form {
  margin-bottom: 0;
}

.font-bold {
  font-weight: 700;
}

.font-base {
  font-size: 14px;
}

.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 10px;
  padding-left: 10px;
}

.d-unset {
  display: unset;
}

.input-icon {
  position: relative;
}

.input-icon:after {
  background: none;
  display: block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f15b';
  width: 12px;
  height: 20px;
  position: absolute;
  top: 14px;
  right: 15px;
  color: #289cf9;
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}

.filter-date-wrap {
  position: relative;
}

.filter-date-wrap:before {
  background: none;
  display: block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f073';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
  color: #289cf9;
}

.radio {
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  cursor: pointer;
}

.iw-44 {
  width: 44px;
}

.ih-44 {
  height: 44px;
}

.w-80 {
  width: 80px !important;
}

.w-100 {
  width: 100%;
}

.leading-44 {
  line-height: 44px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.imt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0;
}

.ip-15 {
  padding: 15px;
}

.ipl-12 {
  padding-left: 12.5px;
}

.imt-15 {
  margin-top: 15px;
}

.ipy-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ip-20 {
  padding: 20px;
}

.ipt-20 {
  padding-top: 20px;
}

.imb-10 {
  margin-bottom: 10px;
}

.imb-20 {
  margin-bottom: 20px;
}

.ipx-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.ipy-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ipt-15 {
  padding-top: 15px;
}

.imt-15 {
  margin-top: 15px;
}

.imb-15 {
  margin-bottom: 15px;
}

.imb-5 {
  margin-right: 5px;
}

.imb-40 {
  margin-bottom: 40px;
}

.imb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.ml-30 {
  margin-left: 30px;
}

@media (max-width: 768px) {
  .sm\:mb-10 {
    margin-bottom: 10px;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-10 {
    margin-left: 10px;
  }

  .sm\:w-165 {
    width: 165px !important;
  }
}

.iborder-e7e7e7 {
  border: 1px solid #e7e7e7;
}

.iborder-ffa300 {
  border: 1px solid #ffa300;
}

.iborder-t-e7e7e7 {
  border-top: 1px solid #e7e7e7;
}

.iborder-t-f1 {
  border-top: 1px solid #f1f1f1;
}

.ifont-14 {
  font-size: 14px;
}

.ifont-18 {
  font-size: 18px;
}

.ifont-24 {
  font-size: 24px;
}

.ibg-f3f2f7 {
  background-color: #f3f2f7;
}

.ibg-289cf9 {
  background-color: #289cf9;
}

.ibg-e5f8ed {
  background-color: #e5f8ed;
}

.hover\:ibg-289cf9:hover {
  background-color: #289cf9;
}

.ibg-74788d {
  background-color: #74788d;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-8 {
  border-radius: 8px;
}

.line-grey {
  border-bottom: 1px solid #ebe9f1;
  width: 100%;
  height: 1px;
  display: block;
}

.no-decoration {
  text-decoration: none !important;
}

.itext-2aa7ff {
  color: #2aa7ff;
}

.itext-1a7c46 {
  color: #1a7c46;
}

.itext-d8 {
  color: #d8d8d8;
}

.color-red {
  color: #e8292b;
}

.color-green {
  color: #248a58;
}

.color-yellow {
  color: #febf48;
}

.color-blue {
  color: #7397d9;
}

.color-blue-dark {
  color: #157cd4;
}

.color-green-light {
  color: #28c76f;
}

.color-violet {
  color: #7367f0;
}

.bg-gradien-1 {
  background-image: linear-gradient(to right, var(--gradient-from-2), var(--gradient-to-2));
}

.bg-gradien-2 {
  background-image: linear-gradient(to right, var(--gradient-from-3), var(--gradient-to-3));
}

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
}

.iw-auto {
  width: auto;
}

.iw-110 {
  width: 110px;
}

.iw-130 {
  width: 130px;
}

.iw-150 {
  width: 150px;
}

.iw-450 {
  width: 450px;
}

.iw-220 {
  width: 220px;
}

.iw-770 {
  width: 770px;
}

@media (min-width: 768px) {
  .sm\:iw-auto {
    width: auto;
  }
}

@media (max-width: 768px) {
  .sm\:iw-450 {
    width: 450px !important;
  }

  .sm\:iw-660 {
    width: 660px !important;
  }

  .sm\:iw-680 {
    width: 680px !important;
  }

  .sm\:iw-770 {
    width: 770px !important;
  }

  .sm\:iw-810 {
    width: 810px !important;
  }
}

.ifont-12 {
  font-size: 12px;
}

.form-control {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ebe9ef;
}

input[placeholder],
[placeholder],
*[placeholder] {
  color: #333;
}

.justify-center {
  justify-content: center;
}

.main-content {
  width: calc(100% - 278px);
  margin-left: auto;
  transition: all 0.4s ease;
  min-height: calc(100vh - 278px);
}

.main-content .inner {
  min-height: calc(100vh - 64px);
}

.main-content .wrapper {
  padding: 0 15px 15px 15px;
}

.wrapper {
  margin-left: auto;
  transition: all 0.4s ease;
  min-height: calc(100vh - 70px);
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .navbar .logo-wrap {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-grey-light-1);
  }

  .navbar .logo-wrap .logo {
    margin: 0 auto;
  }
}

.name-user-pc {
  display: block;
}
.name-user-mobile {
  display: none;
}

@media (max-width: 700px) {
  .banner-e_content {
    width: auto !important;
    min-width: auto !important;
  }
  .name-user-pc {
    display: none;
  }
  .name-user-mobile {
    display: block;
  }
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    min-width: auto !important;
  }
  .navbar-toggler {
    min-width: 70px;
  }
  .navbar .navbar-menu-wrapper .navbar-nav {
    justify-content: flex-end;
  }
}

.navbar .logo {
  display: none;
  height: 30px;
}

.navbar-nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}

/* .nav-link {
    float: left;
    text-align: center;
    text-decoration: none;
    color: #333333;
} */

.nav-link:hover {
  cursor: pointer;
}

.navbar-nav i {
  font-size: 1rem;
}

.navbar .nav-right {
  margin-left: auto;
}

.navbar .nav-right .nav-item {
  margin: 0 10px;
  position: relative;
}

.navbar .nav-notification a {
  padding: 15px 0;
  position: relative;
}

.navbar .nav-notification .ibagde {
  padding: 4px 7px;
  background-color: #f46a6a;
  font-size: 10px;
  color: #ffffff;
  border-radius: 99px;
  height: 16px;
  line-height: 1;
  position: absolute;
  top: 10px;
  right: -10px;
}

.navbar .nav-right .icon-notification {
  font-size: 20px;
}

.navbar .nav-right .icon-user {
  font-size: 12px;
  position: relative;
  top: 1px;
  margin-left: 5px;
}

.navbar .nav-right .nav-select {
  position: relative;
}

.navbar .nav-right .nav-select select {
  cursor: pointer;
  min-width: 320px;
  padding: 9px 15px;
  border-radius: 99px;
  border-color: #e7e7e7;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.navbar .nav-right .nav-select span {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 12px;
}

.navbar-search {
  color: #74788d;
}

.navbar-search i {
  font-size: 0.875rem;
}

.navbar-search-input {
  border: 0;
  color: #74788d;
}

.navbar-search-input:focus,
.navbar-search-input:active,
.navbar-search-input:focus-visible {
  border: 0;
  outline: none;
}

.nav-user .nav-link {
  display: flex;
  align-items: center;
}

.nav-user .nav-link i {
  font-size: 0.75rem;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.dropdown-content {
  position: absolute;
  top: 150%;
  right: 0;
  min-width: 320px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgb(15, 34, 58, 0.12);
  transition: all 0.3s linear;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
}

.nav-user:hover .dropdown-content,
.nav-notification:hover .dropdown-content,
.dropdown-content.active {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.dropdown-content .dropdown-header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-content .dropdown-body ul {
  padding: 0;
  margin: 0;
}

.dropdown-content .dropdown-body li {
  padding: 10px 15px;
  display: flex;
}

.dropdown-content .dropdown-body li:hover {
  background-color: #f8f9fa;
}

.dropdown-content .dropdown-body .icon {
  width: 32px;
  height: 32px;
  border-radius: 99px;
}

.dropdown-content .dropdown-body .content {
  flex: 1;
  padding-left: 15px;
  font-size: 12px;
}

.dropdown-content .dropdown-body .content h6 {
  font-size: 14px;
  margin-bottom: 5px;
}

.dropdown-content .dropdown-body .content p {
  margin-bottom: 5px;
}

.dropdown-content .dropdown-body .content p:last-child {
  margin-bottom: 0;
}

.dropdown-content .dropdown-footer {
  padding: 10px 15px;
  text-align: center;
  border-top: 1px solid #f5f6f8;
}

.dropdown-content .dropdown-header h5 {
  margin-bottom: 0;
  font-size: 16px;
  color: #495057;
}

.dropdown-content .dropdown-header a {
  font-size: 13px;
}

.dropdown-content.dropdown-sm {
  width: 230px;
  min-width: auto;
}

.dropdown-content .linklist {
  padding: 5px 0;
  margin: 0;
  list-style: none;
}

.dropdown-content .linklist li a {
  padding: 8px 20px;
  color: #212529;
  display: block;
}

.dropdown-content .linklist li a:hover {
  background-color: #f8f9fa;
}

.sidebar {
  width: 278px;
  z-index: 999;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  background-color: #051f3e;
}

.navbar-brand-box {
  padding: 0 17px 0 17px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: #089e60;
}

.navbar-brand-box .nav-link {
  color: #ffffff;
}

.navbar-brand-box .logo img {
  height: 46px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  /* min-width: 250px; */
}

.sidebar-menu .sidebar-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #7b8190;
  font-weight: 600;
}

.sidebar-menu .sidebar-item > a {
  padding: 15px 20px 15px 15px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #9ea8b5;
}

.sidebar-menu .sidebar-item {
  position: relative;
  border-bottom: 1px solid #042954;
}

.sidebar-menu .sidebar-item.active .sidebar-sub,
.sidebar-menu .sidebar-item:hover .sidebar-sub {
  display: block;
}

.sidebar-menu > .sidebar-item.active:after,
.sidebar-menu > .sidebar-item.active:before,
.sidebar-menu .sidebar-item:hover:before,
.sidebar-menu .sidebar-item:hover:after {
  opacity: 1;
}

.sidebar-menu .sidebar-item:hover > a,
.sidebar-menu .sidebar-item.active a,
.sidebar-menu .sidebar-item:hover a {
  background-color: #042954;
  color: #fff;
}

.sidebar-menu .sidebar-item [class*=' icon-'],
.sidebar-menu .sidebar-item [class^='icon-'] {
  margin-right: 10px;
  transition: all 0.4s ease;
}

.sidebar-menu .sidebar-item.has-sub > a:after {
  font-family: 'icomoon';
  content: '\e90c';
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 10px;
  position: absolute;
  top: 20px;
  right: 10px;
}

.sidebar-menu .sidebar-sub {
  list-style: none;
  display: none;
  margin: 0;
  background-color: #051f3e;
}

.sidebar-menu .sidebar-sub .sidebar-item a {
  padding: 15px;
  font-weight: 400;
  margin: 0;
  background-color: transparent;
  font-size: 14px;
  display: block;
}

.sidebar-menu .sidebar-sub .sidebar-item a:hover {
  background-color: #042954;
  color: #fff;
}

.sidebar-menu .sidebar-sub .sidebar-item a.curent {
  background-color: #ffeec8;
  border-radius: 0;
}

.sidebar-menu .sidebar-sub .sidebar-item {
  margin-left: 0;
  border-bottom: 0;
  border-top: 1px solid #042954;
}

.overlay-scrollbar {
  overflow-y: scroll;
}

.overlay-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.overlay-scrollbar::-webkit-scrollbar-thumb {
  background: #d1d6db;
  border-radius: 10rem;
  border: 1px solid #d1d6db;
}

.block-info {
  display: flex;
  justify-content: space-between;
}

.block-info .info-item {
  width: calc(50% - 15px);
  padding: 35px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.block-info .info-item .info-icon {
  font-size: 20px;
  width: 44px;
  height: 44px;
  border-radius: 99px;
  background-color: #ffffff;
}

.block-info .info-item .info-content {
  flex: 1;
  padding-left: 15px;
  color: #ffffff;
}

.block-info .info-item h3 {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
}

.block-info .info-item h3 a {
  color: #fff;
}

.block-info .info-item p {
  margin-bottom: 0;
  font-size: 14px;
}

.block-info .info-item .info-icon span {
  position: relative;
  top: 12px;
  left: 13px;
}

.block-linklist {
  display: flex;
  justify-content: space-between;
}

.block-linklist .item {
  width: calc(25% - 22.5px);
  background-color: rgba(255, 163, 0, 0.2);
  padding: 55px 20px 40px 20px;
  border-radius: 10px;
}

.block-linklist .item .icon {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 99px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto 20px auto;
}

.block-linklist .item .icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 18px;
}

.block-linklist .item h3 {
  font-size: 18px;
  margin: 0;
  text-align: center;
  line-height: 1.6;
  font-weight: 600;
}

.block-linklist .item h3 span {
  font-size: 14px;
  font-weight: 400;
}

/* Menu active */
@media (min-width: 1025px) {
  .navbar-brand-box .logo-mini {
    display: none;
  }

  .menu-active .navbar-brand-box .logo-full {
    display: none;
  }

  .menu-active .navbar-brand-box .logo-mini {
    display: block;
  }

  .menu-active .navbar-brand-box .menu-toggle {
    display: none;
  }

  .menu-active .sidebar-menu .sidebar-item {
    margin: 0 15px;
    border-radius: 8px;
  }

  .menu-active .sidebar-menu .sidebar-item.has-sub > a:after,
  .menu-active .sidebar-menu .sidebar-item:before,
  .menu-active .sidebar-menu .sidebar-item:after {
    display: none;
  }

  .menu-active .sidebar-menu .sidebar-sub {
    display: none !important;
    position: absolute;
    left: 100%;
    top: 0;
    width: 200px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    z-index: 99;
    background-color: #fff;
    transition: all 0.3s ease;
    border-radius: 8px;
  }

  .menu-active .sidebar-menu .sidebar-item.active .sidebar-sub {
    display: block !important;
  }

  .menu-active .sidebar-menu .sidebar-item a {
    display: inline-block;
    border-radius: 8px;
  }

  .menu-active .sidebar-menu .sidebar-item a span {
    display: none;
  }

  .menu-active .sidebar-menu .sidebar-item [class*=' icon-'],
  .menu-active .sidebar-menu .sidebar-item [class^='icon-'] {
    display: block;
    margin: 0;
    font-size: 20px;
  }

  .menu-active .sidebar-menu .sidebar-item a:before {
    display: none;
  }

  .menu-active .sidebar {
    width: 80px;
  }

  .menu-active .main-content {
    width: calc(100% - 80px);
  }
}

@media (max-width: 1024px) {
  .sidebar {
    transition: all 0.4s ease;
  }

  .main-content {
    width: 100%;
  }

  .navbar .menu-toggle {
    display: block;
  }

  /* .sidebar {
        left: -100%;
    } */

  .menu-active .sidebar {
    left: 0;
    transition: all 0.4s ease;
  }

  .navbar-brand-box .logo-mini {
    display: none;
  }

  .navbar .logo {
    display: block;
  }

  .navbar .nav-right .nav-select,
  .navbar .nav-right .icon-user,
  .navbar .nav-right .nav-user-name,
  .navbar .nav-date {
    display: none;
  }

  .nav-user img {
    margin-right: 0;
  }

  .block-linklist {
    flex-wrap: wrap;
  }

  .block-linklist .item {
    width: calc(50% - 15px);
    margin-bottom: 30px;
  }
}

.block-test {
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.block-test .test-title {
  font-size: 18px;
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #f1f1f1;
}

.block-test .test-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.block-test .test-nav-left ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.block-test .test-nav-left li {
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}

.block-test .test-nav-left li:before {
  content: '';
  width: 1px;
  height: 15px;
  background-color: #7d7d7d;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.block-test .test-nav-left li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.block-test .test-nav-left li:last-child:before {
  display: none;
}

.form-search {
  position: relative;
}

.form-search .form-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: 0;
  background-color: transparent;
  color: #6e6b7b;
}

.form-search .form-btn svg {
  width: 16px;
}

.block-test .test-nav-right .test-search {
  border-radius: 99px;
  background-color: #f3f2f7;
  border: 1px solid #f3f2f7;
  padding: 4px 20px;
  min-width: 250px;
}

.block-test .test-nav-right .btn {
  padding: 2px 25px;
  border-radius: 99px;
  background-color: #ffa300;
  color: #fff;
}

.block-test .test-nav-right .btn:hover {
  background-color: #000;
  color: #fff;
}

.block-test table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.block-test table thead {
  background-color: #f3f2f7;
  border-top: 1px solid #ebe9f1;
  border-bottom: 2px solid #ebe9f1;
  font-size: 12px;
  font-weight: bold;
  color: #6e6b7b;
  text-transform: uppercase;
}

.block-test table tr {
  border-bottom: 1px solid #e7e7e7;
}

.block-test table th,
.block-test table td {
  padding: 12px 15px;
  text-align: center;
}

.block-test table tbody td {
  padding: 8px 15px;
}

.table-heading {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.block-table table tbody td .td-link {
  min-width: 150px;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 99px;
  background-color: #f1f1f1;
  color: #6e6b7b;
  font-size: 14px;
}

@media (max-width: 991px) {
  .block-table table tbody td .td-link {
    min-width: 125px;
  }
}

.block-table table tbody td .out-of-date {
  background-color: #fbdddd;
  color: #ea5455;
  text-decoration: underline;
  text-underline-position: under;
}

.block-table table tbody td .now {
  background-color: #d4f4e2;
  color: #28c76f;
  text-decoration: underline;
  text-underline-position: under;
}

.block-table table tbody td .contact {
  background-color: #e2e1e5;
  color: #999999;
  text-decoration: underline;
  text-underline-position: under;
}

.block-table table tbody td .submit {
  background-color: #e3e1fc;
  color: #7367f0;
  text-decoration: underline;
  text-underline-position: under;
}

.block-table table tbody td .blue {
  background-color: #e2f4fd;
  color: #157cd4;
  text-decoration: underline;
  text-underline-position: under;
}

.block-test table a {
  text-decoration: underline;
  text-underline-position: under;
  color: #7367f0;
  text-decoration: underline;
  text-underline-position: under;
}

.block-test .point {
  padding: 10px;
  background-color: #d4f4e2;
  border-radius: 99px;
  display: inline-block;
  color: #28c76f;
  font-weight: bold;
  text-decoration: underline;
  text-underline-position: under;
}

.block-question-wrap {
  display: flex;
  justify-content: space-between;
}

.block-question {
  width: calc(100% - 410px);
}

.block-question .info-mb {
  display: none;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}

.block-question .page-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.block-question .page-body {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
  border-radius: 10px;
}

.block-question .question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.block-question .question-header h2 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.block-question .question-header a {
  padding: 5px 20px;
  background-color: #ffedcc;
  border-radius: 8px;
  color: #333333;
}

.block-question .question {
  height: 260px;
}

.block-question .answer {
  padding: 12.5px 0;
  border-radius: 10px;
  background-color: #f3f2f7;
}

.block-question .answer label {
  margin-bottom: 0;
}

.block-question .answer li {
  padding: 7.5px 20px;
}

.block-question .answer li.true {
  background-color: #28c76f;
  color: #ffffff;
}

.block-question .answer li.false {
  background-color: #ea5455;
  color: #ffffff;
}

.block-question .answer li:last-child {
  margin-bottom: 0;
}

.block-question .answer li input[type='radio'] {
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  top: 1px;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.block-question .btn-secondary {
  padding: 12px 20px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 99px;
  background-color: #28c76f;
  transition: all 0.3s ease;
  border-color: #28c76f;
}

.block-question .btn-wrap {
  text-align: center;
}

.block-question .btn-secondary:hover {
  background-color: #ea5455;
  border-color: #ea5455;
}

.block-question .block-text {
  padding: 20px;
  background-color: #fff6e5;
  border-radius: 10px;
  display: none;
  transition: all 0.3s ease;
}

.block-question .block-text .text-inner {
  height: 150px;
}

.block-question .block-text.active {
  display: block;
}

.block-question-info {
  width: 380px;
}

.block-question-info .info-inner {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.block-question-info .info {
  padding: 15px;
}

.block-question-info .info li:not(:last-child) {
  margin-bottom: 5px;
}

.block-question-info .time-countdown {
  padding: 15px;
  text-align: center;
  background-color: #fff6e5;
  border-top: 1px solid #ffe3b2;
  border-bottom: 2px solid #ffe3b2;
}

.block-question-info .time-countdown h3 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

.block-question-info #countdown {
  display: flex;
  justify-content: center;
}

.block-question-info #countdown li {
  font-size: 28px;
  font-weight: bold;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  display: inline-block;
  line-height: 1.7;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
}

.block-question-info #countdown li.dots {
  border: 0;
  background-color: transparent;
  width: 36px;
}

.test-number {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 12.5px;
  margin-bottom: 15px;
}

.test-number li {
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin: 2.5px;
  background-color: #f3f2f7;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}

.test-number li.active {
  background-color: #ffa300;
}

.test-number li.active a {
  color: #ffffff;
}

.test-number li.true {
  background-color: #28c76f;
}

.test-number li.true a {
  color: #ffffff;
}

.test-number li.false {
  background-color: #ea5455;
}

.test-number li.false a {
  color: #ffffff;
}

.test-number li a {
  display: block;
}

.block-question-info .btn {
  width: 100%;
  border-radius: 10px;
  background-image: linear-gradient(to right, var(--gradient-from-1), var(--gradient-to-1));
  padding: 12px 20px;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.3s ease;
}

.block-question-info .btn:hover {
  background-image: linear-gradient(to right, var(--gradient-to-1), var(--gradient-to-1));
}

.block-result {
  display: flex;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #e5f8ed;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
}

@media (max-width: 768px) {
  .block-result {
    flex-direction: column;
  }

  .block-result .point {
    margin: 0 auto 20px;
  }

  .block-result .info {
    padding-left: 0;
  }
}

.block-result .point {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  background-color: #e5f8ed;
  font-size: 12px;
  text-transform: uppercase;
  color: #1a7c46;
  text-align: center;
  padding-top: 14px;
}

.block-result .point strong {
  font-size: 32px;
}

.block-result .info {
  flex: 1;
  padding-left: 15px;
  color: #333333;
}

.block-result .info span {
  font-weight: 700;
}

.block-result .info h1 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.block-result .info li {
  margin-bottom: 5px;
}

/* .answer input[type="radio"] {
    display: none;
}

.answer label span {
    display: none;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
}

.answer .false label svg {
    color: #ea5455;
} */

/* input[type="radio"] + label span {
    transition: background .2s,
    transform .2s;
    margin-right: 5px;
    position: relative;
    top: -1px;
    cursor: pointer;
    border-radius: 3px;
}

input[type="radio"] + label span svg {
    opacity: 0;
}

input[type="radio"]:checked + label span svg{
    opacity: 1;
    stroke-width: .2px;
    color: #000;
} */

.iwe-modal .modal-header {
  padding: 10px 20px;
  background-color: #28c76f;
  border: 0;
}

.iwe-modal .modal-header .modal-title {
  font-size: 18px;
  color: #ffffff;
}

.iwe-modal .modal-body {
  padding: 40px 20px 20px 20px;
  text-align: center;
}

.iwe-modal .modal-footer {
  padding: 0 20px 20px 20px;
  border: 0;
  justify-content: center;
}

.iwe-modal .modal-footer .btn {
  padding: 7px 25px;
  background-color: #28c76f;
  color: #fff;
  margin: 0 5px;
  border-radius: 0;
  min-width: 100px;
}

.iwe-modal .modal-footer .btn-grey {
  background-color: #e7e7e7;
  color: #333333;
}

.iwe-modal .error {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.iwe-modal .error-left {
  width: calc(40% - 30px);
}

.iwe-modal .error-right {
  width: 60%;
  padding: 20px 5px 20px 20px;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.iwe-modal .error select {
  padding: 10px;
  display: block;
  width: 100%;
  border-color: #e7e7e7;
  border-radius: 8px;
  margin-bottom: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.iwe-modal .error .select-wrap {
  position: relative;
}

.iwe-modal .error .select-wrap span {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 12px;
}

.iwe-modal .error textarea {
  border-color: #e7e7e7;
  border-radius: 8px;
  width: 100%;
}

.iwe-modal .error-text {
  height: 200px;
}

.modal-error .modal-header {
  background-color: #f3f2f7;
}

.modal-error .modal-header .modal-title {
  font-size: 14px;
  color: #333333;
}

.modal-error .modal-body {
  padding: 20px;
}

.modal-error .modal-footer hr {
  width: 100%;
  margin-bottom: 20px;
  border-top: 1px solid #e7e7e7;
}

.hiden-pc {
  display: none;
}

.hiden-mb {
  display: block;
}

.student-heading {
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.student-heading .sub-heading {
  font-size: 14px;
  color: #333333;
}

.student-heading .sub-heading .icon {
  font-size: 10px;
  padding: 0 20px;
}

.student-info {
  margin-bottom: 10px;
}

.student-info li {
  margin-bottom: 5px;
}

.block-student {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.block-student .student {
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.block-student .student-inner {
  padding: 15px 20px;
  border: 1px solid #ebe9f1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #6e6b7b;
  cursor: pointer;
  height: 100%;
}

.block-student .student:hover .student-inner {
  border: 1px solid #ffa300;
  box-shadow: 0 0 10px 0 rgba(255, 163, 0, 0.5);
  color: #ffa300;
}

.block-student .student .student-inner .link a:hover,
.block-student .student:hover .student-inner a {
  color: #ffa300;
}

.block-student .student .student-inner .link a {
  color: #949399;
}

.block-student .create-student .student-inner {
  border: 1px dashed #ffa300;
}

.block-student .create-student .icon,
.block-student .create-student a {
  color: #ffa300;
}

.block-student .student .icon {
  font-size: 20px;
  margin-right: 10px;
}

.block-student .student span span {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.block-student .student .link {
  margin-left: auto;
  position: relative;
  border: 0;
  background-color: #ffffff;
}

.block-student .student .link ul {
  padding-left: 20px;
  border-left: 1px solid #e1e0e6;
}

.block-student .student .link ul li {
  padding: 2px 0;
}

.block-student .student .link ul li a {
  color: #949399;
}

.block-student .student .link ul li a:hover {
  color: #ffa300;
}

.block-student .student .dropdown-link {
  display: none;
  width: 220px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9;
  background-color: #fff;
  color: #6e6b7b;
}

.block-student .student .dropdown-link.dropdown-sm {
  width: 100px;
}

.block-student .student .link:hover .dropdown-link {
  display: block;
}

.block-student .student .dropdown-link span {
  margin-right: 10px;
}

.block-student .student .dropdown-inner {
  margin: 7px 0;
  padding: 8px 17px;
  background-color: #f3f2f7;
}

.block-student .student .dropdown-link {
  padding: 7px 0;
}

.block-student .student .dropdown-link li {
  padding: 8px 17px;
  background-color: #f3f2f7;
  text-align: left;
  display: flex;
  align-items: center;
}

.block-student .student .dropdown-link a {
  color: #6e6b7b;
  flex: 1;
}

.block-detail-student {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.block-detail-student .detail-student-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 20px;
}

.block-detail-student .detail-student-header h2 {
  margin-right: auto;
  margin-bottom: 0;
  font-size: 14px;
}

.block-detail-student .detail-student-header .student-search {
  width: 250px;
  border-radius: 99px;
  background-color: #f3f2f7;
  position: relative;
  padding: 0 20px;
}

.block-detail-student .detail-student-header .student-search input {
  display: block;
  width: 100%;
  border: 0;
  background-color: transparent;
  height: 30px;
}

.block-detail-student .detail-student-header .student-search button {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.block-detail-student .detail-student-header .student-search button svg {
  width: 15px;
}

.htable-link,
.block-detail-student .detail-student-header .student-link a {
  color: #289cf9;
  padding: 6px 20px;
  border-radius: 99px;
  background-color: #ffa300;
  color: #fff;
}

.htable-link:hover,
.block-detail-student .detail-student-header .student-link a:hover {
  background-color: #000;
  color: #fff;
}

.block-detail-student .student-link .icon {
  font-size: 10px;
  margin-left: 5px;
}

.block-detail-student .student-link > ul {
  display: flex;
}

.block-detail-student .student-link > ul > li {
  margin-left: 20px;
  color: #289cf9;
  position: relative;
  cursor: pointer;
}

.block-detail-student .student-link > ul > li ul {
  display: none;
  position: absolute;
  top: 100%;
  width: 150px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 99;
}

.block-detail-student .student-link > ul > li ul li a {
  color: #333333 !important;
  display: block;
  padding: 5px 10px;
}

.icontent-dropdown {
  width: 230px !important;
}

.icontent-dropdown li a {
  background-color: transparent !important;
}

.block-detail-student .student-link > ul > li ul li a:hover,
.icontent-dropdown li a:hover {
  color: #ffa300 !important;
}

.block-detail-student .student-link > ul > li ul li:hover a {
  background-color: #d4ebfe;
}

.itxt-dropdown {
  display: inline-block;
  padding: 10px 0;
}

.block-detail-student .student-link > ul > li:hover ul {
  display: block;
}

.block-detail-student .block-table table {
  width: 100%;
}

.block-detail-student .block-table table .bagde {
  padding: 5px 15px;
  border-radius: 99px;
  background-color: #e2f4fd;
}

.block-detail-student .block-table table thead {
  background-color: #f3f2f7;
  border-top: 1px solid #ebe9f1;
  border-bottom: 2px solid #ebe9f1;
}

.block-detail-student .block-table table thead th {
  padding: 13px 15px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}

.block-detail-student .block-table table tbody tr {
  border-top: 1px solid #ebe9f1;
}

.block-detail-student .block-table table tbody td {
  padding: 10px 15px;
  text-align: center;
}

.block-table table .action {
  display: flex;
  justify-content: center;
}

.block-table table tbody .action a {
  padding: 8px;
  font-size: 14px;
  width: 30px;
  height: 30px;
  background-color: #f1f1f1;
  border-radius: 8px;
  display: block;
  margin: 0 5px;
  color: #6e6b7b;
  text-decoration: none;
}

.block-table table tbody .action a.eye {
  background-color: #e2f4fd;
  color: #157cd4;
}

.block-table table tbody .action a.edit {
  background-color: #e3e1fc;
  color: #7367f0;
}

.block-table table tbody .action a.delete {
  background-color: #fbdddd;
  color: #ea5455;
}

.block-detail-student .block-table table tbody .label {
  padding: 7px 20px;
  background-color: #e2f4fd;
  border-radius: 99px;
}

/* Modal Student */
.student-modal .modal-dialog {
  max-width: 560px;
}

.student-modal .modal-header {
  padding: 14px 20px;
  background-color: #f3f2f7;
  border: 0;
}

.student-modal .modal-title {
  font-size: 14px;
  font-weight: 700;
  color: #333333;
}

.student-modal .modal-content {
  border-radius: 10px;
  overflow: hidden;
}

.student-modal .block-suggest {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.student-modal .block-suggest li {
  padding: 4px 25px;
  border-radius: 99px;
  font-weight: 700;
  background-color: #f3f2f7;
  margin-right: 10px;
  margin-bottom: 10px;
}

.student-modal .block-suggest li a {
  color: #6e6b7b;
}

.student-modal .modal-body h6 {
  font-size: 14px;
  margin-bottom: 15px;
}

.student-modal .input-block {
  border-radius: 99px;
  border: 1px solid #ebe9f1;
  position: relative;
  padding: 8px 20px;
  margin-bottom: 10px;
}

.student-modal .input-block input {
  border: 0;
  display: block;
  width: 100%;
  color: #6e6b7b;
}

.student-modal .input-block button {
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #289cf9;
  font-weight: 700;
}

.student-modal .input-label {
  padding-left: 20px;
  color: #999999;
  font-size: 12px;
}

.student-modal .block-content {
  padding: 0 20px 20px 20px;
  min-height: 40px;
}

.student-modal .modal-footer {
  padding: 20px 0;
  margin: 0 20px;
  border-color: #f3f2f7;
}

.btn-form,
.student-modal .modal-footer .btn {
  padding: 12px 50px;
  margin: 0 0 0 10px;
  border-radius: 10px;
  border: 1px solid #ebe9f1;
  text-transform: uppercase;
  color: #6e6b7b;
}

.btn-form,
.student-modal .modal-footer .btn-blue {
  background-color: #289cf9;
  color: #ffffff;
}

.student-modal .modal-footer .btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.student-modal .modal-footer .notical {
  margin-right: auto;
  color: #289cf9;
}

@media (max-width: 560px) {
  .student-modal .modal-footer .notical {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .student-modal .modal-footer {
    justify-content: center;
  }
}

.student-modal .block-list {
  display: flex;
  flex-wrap: wrap;
}

.student-modal .block-list li {
  border-radius: 99px;
  background-color: #e2f4fd;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}

.student-modal .block-list input {
  border: 0;
  padding: 5px 35px 5px 20px;
  font-weight: 700;
  color: #289cf9;
  background-color: transparent;
  text-align: center;
}

.student-modal .block-list .icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #289cf9;
}

.add-student-modal .modal-dialog {
  max-width: 816px;
}

.add-student-modal .modal-dialog .modal-content {
  border-radius: 10px;
}

.add-student-modal .modal-header {
  padding: 14px 0;
  margin: 0 20px;
  border-bottom: 1px solid #f3f2f7;
}

.add-student-modal .modal-header .modal-title {
  font-size: 14px;
}

.add-student-modal .modal-body label {
  display: block;
}

.add-student-modal .modal-body select,
.add-student-modal .modal-body input {
  width: 100%;
  height: 35px;
  border: 1px solid #ebe9f1;
  border-radius: 10px;
  padding: 0 10px;
  color: #6e6b7b;
}

.add-student-modal .modal-footer {
  padding: 20px 0;
  margin: 0 20px;
  border-top: 1px solid #f3f2f7;
  justify-content: center;
}

.add-student-modal .modal-footer .btn {
  min-width: 160px;
  padding: 7px 15px;
  margin: 0 5px;
  background-color: #e0e0e0;
  color: #333;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.add-student-modal .modal-footer .btn-blue {
  background-color: #289cf9;
  color: #fff;
}

.add-student-modal .modal-footer .btn:hover {
  background-color: #000;
  color: #fff;
}

.notifi-modal .modal-header {
  padding: 15px 0;
  margin: 0 20px;
  border-bottom: 1px solid #f3f2f7;
}

.notifi-modal .modal-header .modal-title {
  font-size: 14px;
}

.notifi-modal .modal-footer {
  padding: 20px 0;
  margin: 0 20px;
  border-top: 1px solid #f3f2f7;
}

.notifi-modal .modal-footer .btn {
  min-width: 160px;
  padding: 7px 15px;
  background-color: #e0e0e0;
  border-radius: 8px;
  color: #333333;
  margin: 0 5px;
}

.notifi-modal .modal-footer .btn-blue {
  background-color: #289cf9;
  color: #ffffff;
}

.notifi-modal .modal-footer .btn:hover {
  background-color: #000;
  color: #ffffff;
}

.advance-search {
  margin-bottom: 30px;
}

.frm-sort > div,
.advance-search > div {
  display: flex;
  align-items: center;
}

.frm-sort .input-control,
.advance-search .input-control {
  flex: 1;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #ebe9f1;
  height: 40px;
  cursor: pointer;
}

.advance-search .bootstrap-select .btn {
  background-color: #fff;
  border-color: #ebe9f1;
  color: #333333;
  height: 40px;
  border-radius: 10px;
}

.advance-search .bootstrap-select .btn:hover {
  background-color: #fff;
  border-color: #ebe9f1;
  color: #333333;
}

.advance-search .bootstrap-select .dropdown-toggle::after {
  border: 0;
  display: block;
  width: 7px;
  height: 7px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(225deg);
  position: relative;
  top: -2px;
  left: 5px;
}

.advance-search .bootstrap-select .inner {
  border-radius: 0;
  height: 200px !important;
  min-height: 200px !important;
  margin: 10px 0;
}

.advance-search .bootstrap-select .filter-option-inner {
  padding: 4px;
}

.frm-sort label,
.advance-search label {
  margin-bottom: 0;
  font-size: 14px;
  margin-right: 15px;
}

.advance-search .btn-search {
  padding: 7px 30px;
  border-radius: 10px;
  background-color: #289cf9;
  color: #ffffff;
  text-transform: uppercase;
}

.advance-search .btn:hover {
  background-color: #000;
  color: #fff;
}

.statistic-modal .modal-dialog {
  width: 365px;
}

.statistic-modal .modal-header {
  padding: 10px 20px;
  background-color: #e9f5fe;
  justify-content: center;
  border: 0;
}

.statistic-modal .modal-header .modal-title {
  color: #289cf9;
  font-weight: 700;
}

.statistic-modal .modal-body li {
  position: relative;
  justify-content: space-between;
  display: flex;
  margin-bottom: 15px;
}

.statistic-modal .modal-body li:last-child {
  margin-bottom: 0;
}

.statistic-modal .modal-body li:before {
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #ececec;
  position: absolute;
  bottom: 5px;
}

.statistic-modal .modal-body span {
  background-color: #fff;
  position: relative;
  font-weight: 700;
}

.statistic-modal .modal-body .point {
  padding-right: 20px;
}

.statistic-modal .modal-body .quantity {
  padding-left: 20px;
}

.statistic-modal .modal-footer {
  padding: 20px 0;
  margin: 0 20px;
  justify-content: center;
}

.statistic-modal .modal-footer .btn {
  background-color: #289cf9;
  padding: 10px 60px;
  border-radius: 10px;
  margin: 0;
  color: #fff;
}

.step-wrap .steps,
#stepWrap .steps {
  display: none;
}

.step-nav,
#stepWrap .steps ul {
  display: flex;
}

.step-nav li,
#stepWrap .steps ul li {
  width: 25%;
  background-color: #f3f2ee;
  text-align: center;
  color: #999;
  display: block;
  transition: all 0.3s ease;
  cursor: pointer;
}

.step-nav li a {
  display: block;
  padding: 12px 15px;
}

.step-nav li span {
  font-size: 15px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.step-wrap .steps ul li span,
#stepWrap .steps ul li span {
  display: none;
}

.step-nav li:hover,
.step-nav li.active,
#stepWrap .steps ul li.current {
  background-color: #289cf9;
  color: #fff;
}

.step-nav li:hover a,
.step-nav li.active a,
#stepWrap .steps ul li.current a {
  color: #ffffff;
}

.step-wrap .content,
#stepWrap .content {
  padding: 30px 0;
}

.step-wrap .content h6,
#stepWrap .content h6 {
  display: none;
}

/* .step-wrap .actions li a[href="#previous"],
#stepWrap .actions li a[href="#previous"] {
    display: none;
} */

.step-wrap .actions ul,
#stepWrap .actions ul {
  display: flex;
  justify-content: space-between;
}

.step-wrap .actions.frm-comfirn ul {
  justify-content: center;
}

/* .step-wrap .actions ul li,
#stepWrap .actions ul li {
    margin-left: 10px;
} */

.step-wrap .actions ul li input[type='submit'],
.step-wrap .actions ul li a,
#stepWrap .actions ul li a {
  padding: 10px 40px;
  display: block;
  border-radius: 10px;
  background-color: #289cf9;
  color: #ffffff;
  border: 0;
}

/* .step-wrap .actions ul li.disabled,
#stepWrap .actions ul li.disabled {
    display: none;
} */

/* #stepInit .actions ul li.disabled,
#stepInit .actions ul li.disabled {
    display: none;
} */
#stepInit .actions ul li.disabled,
#stepInit .actions ul li.disabled {
  display: none;
}

/* Select Room */

.select-room {
  padding: 20px 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.select-room-all label,
.select-room label {
  margin-bottom: 0;
}

.select-room-all input,
.select-room input {
  position: relative;
  top: 4px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.input-file-wrap {
  max-width: 600px;
  height: 44px;
  border-radius: 10px;
  border: 1px solid #aad9ff;
  margin: 0 auto;
}

.input-file-wrap .input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file-wrap label {
  margin-bottom: 0;
  width: 130px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  margin-left: auto;
  display: inherit;
  background-color: #289cf9;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
}

.upload-wrap {
  max-width: 560px;
  margin: 0 auto;
}

.upload-wrap .form-group {
  position: relative;
}

.upload-wrap .form-group .label-text {
  height: 50px;
  min-width: 150px;
  text-align: center;
  padding: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #f3f2f7;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-wrap .form-upload-file input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-wrap .form-upload-file label {
  background-color: transparent;
  position: relative;
  padding: 30px 70px;
  display: block;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  color: #289cf9;
  font-weight: 700;
  cursor: pointer;
}

.upload-wrap .form-upload-file label .icon {
  width: 40px;
  height: 40px;
  border-radius: 99px;
  display: block;
  background-color: #289cf9;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.upload-wrap .form-upload-file label .icon i {
  position: relative;
  top: 11px;
  left: 3px;
  color: #fff;
  font-size: 18px;
}

.upload-wrap .form-group label i {
  margin-right: 5px;
}

.upload-wrap .form-group .form-control {
  padding-left: 165px;
}

.exam-detail .exam-header {
  display: flex;
}

.exam-detail .exam-header li {
  width: 50%;
}

.exam-detail .exam-header li {
  float: none;
  border: 0;
  margin-bottom: 0;
  font-size: 12px;
  border-radius: 0;
  background-color: #f1f1f1;
  padding: 11px 15px;
  text-align: center;
}

.exam-detail .exam-header li.active {
  background-color: #289cf9;
  color: #ffffff;
}

.exam-detail .exam-body {
  padding: 15px;
}

.exam-detail .exam-list ul {
  margin-left: -7.5px;
  margin-right: -7.5px;
  display: flex;
  flex-wrap: wrap;
}

.exam-detail .exam-list li {
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.exam-detail .exam-list li input {
  pointer-events: none;
}

.exam-detail .exam-list li input,
.exam-detail .exam-list li a {
  display: block;
  width: 63px;
  height: 63px;
  line-height: 63px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ebe9f1;
  font-weight: 700;
}

.exam-detail .exam-list li.active input,
.exam-detail .exam-list li.active a {
  background-color: #d4ebfe;
}

.exam-detail .radio-wrap input[type='radio'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.exam-detail .radio-wrap input[type='radio'] + label {
  -webkit-appearance: none;
  border: 1px solid #ebe9f1;
  border-radius: 10px;
  display: block;
  position: relative;
  padding: 30px 20px;
  margin-bottom: 0;
  text-align: center;
  cursor: pointer;
}

.exam-detail .radio-wrap input[type='radio']:checked + label {
  border: 1px solid #ffa300;
  box-shadow: 0 0 10px 0 rgba(255, 163, 0, 0.12);
}

.exam-detail .exam-tabs {
  border: 1px solid #e5e5e5;
}

.exam-detail .exam-tabs .tab-content {
  padding: 15px;
}

.exam-detail .exam-content {
  padding: 15px;
  padding-right: 5px;
  background-color: #efefef;
  border: 1px solid #e5e5e5;
}

.exam-detail .exam-content-inner {
  height: 510px;
  overflow-y: scroll;
  padding-right: 10px;
}

.exam-detail .exam-content-item {
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 1200px) {
  .frm-sort {
    margin-top: 20px;
  }

  .frm-sort > div,
  .advance-search > div {
    margin-bottom: 20px;
  }

  .frm-sort label,
  .advance-search label {
    min-width: 90px;
  }

  .advance-search .btn {
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .block-detail-student .block-table,
  .block-test .block-table {
    overflow-x: scroll;
  }

  .block-detail-student .block-table table,
  .block-test table {
    width: 1200px;
  }
}

@media (max-width: 991px) {
  .main-content .wrapper {
    padding: 0 15px 15px 15px;
  }

  .step-nav li span.title {
    display: none;
  }

  .step-nav li span {
    margin-right: 0;
  }

  .iwe-modal .error {
    flex-direction: column;
  }

  .iwe-modal .error-right,
  .iwe-modal .error-left {
    width: 100%;
  }

  .iwe-modal .error-left {
    margin-bottom: 20px;
  }

  .block-student .student {
    width: 50%;
  }

  .block-detail-student .detail-student-header {
    flex-direction: column;
    margin-bottom: 5px;
  }

  .block-detail-student .detail-student-header h2 {
    margin: 10px 0 10px 0;
    text-align: center;
  }

  .block-detail-student .detail-student-header .student-search {
    margin-bottom: 10px;
  }

  .block-detail-student .detail-student-header .student-search {
    width: auto;
    max-width: 250px;
  }

  .col-20 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .hiden-mb {
    display: none;
  }

  .hiden-pc {
    display: block;
  }

  .block-question .info-mb {
    display: block;
    margin-bottom: 30px;
  }

  .block-info {
    flex-wrap: wrap;
  }

  .block-info .info-item {
    width: 100%;
  }

  .block-linklist .item {
    width: 100%;
  }

  .block-question-wrap {
    flex-direction: column;
  }

  .block-question-info .time-countdown {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .block-question-info,
  .block-question {
    width: 100%;
  }

  .block-question-info .info {
    display: none;
  }

  .block-question {
    margin-bottom: 30px;
  }

  .block-test .test-title {
    text-align: center;
  }

  .block-test .test-nav {
    flex-direction: column;
  }

  .block-test .test-nav .test-nav-left {
    margin-bottom: 10px;
  }

  .block-test .test-nav-left ul {
    justify-content: center;
  }

  .navbar .nav-right .nav-item {
    position: static;
  }

  .add-student-modal .modal-body .row > div {
    margin-bottom: 20px;
  }

  #stepWrap .steps ul {
    flex-direction: column;
  }

  #stepWrap .steps ul li {
    width: 100%;
  }

  /* .col-20 {
        flex: 0 0 50%;
        max-width: 50%;
    } */
}

@media (max-width: 560px) {
  .nav-notification .dropdown-content {
    min-width: 100%;
    top: 101%;
  }

  .block-student .student {
    width: 100%;
  }

  .exam-detail .exam-tabs .nav-item {
    width: 100%;
  }
}

/* LOGIN */
.login-page {
  background-color: #efedfb;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.login-wrap {
  padding: 30px;
}

.left-form,
.right-form {
  width: 50%;
}

.right-form img {
  width: 100%;
}

@media (max-width: 1024px) {
  .right-form {
    display: none;
  }

  .left-form {
    width: 100%;
  }
}

.login-container {
  margin: auto;
  width: 1024px;
}

.form-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  margin-bottom: 5px;
}

.form-wrapper.form-wrapper-sm {
  width: 1024px;
  display: block;
}

.header-form-loginPGD .title-header-form-loginiPGD {
  font-weight: bold;
  font-size: 16px;
  color: var(--color-blue-2);
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 1024px) {
  .login-container {
    width: 80%;
  }
  .form-wrapper.form-wrapper-sm,
  .form-wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .login-container {
    width: 90%;
  }
  .form-wrapper.form-wrapper-sm,
  .form-wrapper {
    width: 100%;
  }
}

@media (max-width: 285px) {
  .logo-container_text {
    display: none;
  }
}

.form-wrapper p {
  margin-bottom: 10px;
}

.tab-panels .tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.tab-panels .tabs li {
  position: relative;
  width: 50%;
}

.tab-panels .tabs li:first-child span {
  border-bottom-right-radius: 30px;
}

.tab-panels .tabs li:first-child a:after {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #ffb900;
  position: absolute;
  bottom: 0;
  right: -1px;
  z-index: -1;
}

.tab-panels .tabs li:first-child a.active:after {
  background-color: #ffffff;
}

.tab-panels .tabs li:last-child span {
  border-top-left-radius: 30px;
}

.tab-panels .tabs li:last-child a:after {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #ff8b00;
  position: absolute;
  top: 0;
  left: -1px;
  z-index: -1;
}

.tab-panels .tabs li:last-child a.active:after {
  background-color: #ffffff;
}

.tab-panels .tabs a {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  text-align: center;
  color: #333333;
}

.tab-panels .tabs a.active {
  color: #ffffff;
}

.tab-panels .tabs li span {
  display: block;
  padding: 20px 10px;
  background-color: #ffffff;
}

.tab-panels .tabs a.active span {
  background-image: linear-gradient(to right, #ffb900, #ffb900, #ff8b00);
}

.tab-panels .panel-container {
  padding: 30px;
}

.form-logo {
  text-align: center;
  margin-bottom: 30px;
}

.form-body .form-title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
}

.form-body .form-notification {
  text-align: center;
  color: #666666;
}

.form-body .form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-body .form-group .form-input {
  padding-left: 65px;
}

.form-body .form-local .form-input {
  padding-left: 110px;
}

.form-body .form-input {
  height: 50px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 3px rgba(24, 52, 89, 0.2);
}

.form-body .form-group-select {
  border-radius: 8px;
  border: 1px solid #fff3d5;
}

.form-body .form-select {
  height: 50px;
  width: calc(100% - 50px);
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 50px;
  border: 0;
  padding-left: 14px;
  cursor: pointer;
  box-shadow: inset 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.form-body .form-radio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.form-body .form-radio .radio-wrap {
  line-height: 1;
}

.form-body .form-radio label {
  margin-bottom: 0;
  font-weight: 700;
  position: relative;
  top: -5px;
  margin-left: 5px;
}

.form-body .form-radio .text {
  width: 100%;
  margin-bottom: 10px;
}

.form-body .form-radio input {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.form-body .form-checkbox {
  line-height: 1;
  display: flex;
  align-items: center;
}

.form-body .form-checkbox label {
  font-size: 12px;
  color: #007bff;
  margin-bottom: 0;
  margin-left: 10px;
}

.form-body .form-checkbox input {
  background-color: #f1f1f1;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.form-body .form-btn {
  height: 50px;
  padding: 14px 10px;
  min-width: 250px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #fee39c;
  box-shadow: 0 2px 4px rgba(254, 227, 156, 0.12);
  color: #ffffff;
}

.form-body .form-btn:hover {
  background-image: none;
  background-color: #ff8b00;
}

.form-body .btn-zalo {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  color: #0180c7;
  display: block;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.form-body .btn-zalo:hover {
  box-shadow: none;
}

.form-body .btn-zalo img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.form-body .form-link {
  color: #234b99;
  font-weight: 700;
}

.form-body .or {
  position: relative;
  text-align: center;
  margin: 20px 0 15px 0;
}

.form-body .or span {
  display: inline-block;
  background-color: #ffffff;
  padding: 0 15px;
  font-weight: 700;
}

.form-body .or:after {
  content: '';
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.form-body .form-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form-body .form-icon img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #ffffff;
}

.form-body .icon-clock img {
  width: 16px;
  height: 21px;
}

.form-body .local-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.form-body .local-icon span {
  font-weight: 700;
}

.form-body .local-icon:after {
  content: '';
  height: 30px;
  width: 1px;
  background-color: #f1f1f1;
  margin-left: 15px;
}

.form-body .local-icon img {
  width: 24px;
  height: 25px;
  margin-right: 10px;
}

.form-body .icon-eye {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 15px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
}

.form-body .icon-eye.show:before {
  content: '';
  width: 1px;
  height: 10px;
  transform: rotate(45deg);
}

.form-body .icon-eye img {
  width: 20px;
  height: 12px;
}

.form-body .form-otp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-body .form-otp .form-input-otp {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  text-align: center;
  box-shadow: inset 3px 3px 10px rgba(0, 0, 0, 0.1);
  font-size: 28px;
  font-weight: 500;
}

.form-info .steps,
.form-info .title {
  display: none;
}

.form-info .actions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-info .actions a {
  height: 50px;
  padding: 14px 10px;
  min-width: 180px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin: 0 2.5px;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #fee39c;
  box-shadow: 0 2px 4px rgb(254 227 156 / 12%);
  color: #ffffff;
  background-image: linear-gradient(to right, #ffb900, #ffb900, #ff8b00);
}

.form-info .actions a[href='#previous'] {
  background-image: none;
  border: 1px solid #333333;
  color: #333333;
}

.form-info .actions a:hover,
.form-info .actions a[href='#previous']:hover {
  background-image: none;
  background-color: #ff8b00;
  border-color: #ff8b00;
  color: #ffffff;
}

.form-info .disabled {
  display: none;
}

.datepicker {
  left: 552px !important;
}

@media (max-width: 515px) {
  .form-body .form-otp .form-input-otp {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 465px) {
  .form-body .form-otp .form-input-otp {
    width: 40px;
    height: 40px;
  }
}

.form-body .form-coundown {
  text-align: center;
  color: #ea0000;
}

.login-page .resend-code {
  text-align: center;
  margin-top: 15px;
}

.login-page .resend-code a {
  text-decoration: underline;
  color: #234b99;
  text-underline-position: under;
}

.bg-gradient {
  background-image: linear-gradient(to right, #ffb900, #ffb900, #ff8b00);
}

/**/
.carousel-banner .carousel-control-prev,
.carousel-banner .carousel-control-next {
  width: 40px;
  height: 40px;
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-banner .carousel-control-next-icon,
.carousel-banner .carousel-control-prev-icon {
  position: relative;
  top: 9px;
  width: 15px;
}

.news-sidebar {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 30px;
}

.news-sidebar h3 {
  font-size: 20px;
  padding: 15px;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
}

.news-sidebar .sidebar-item {
  padding: 15px 0;
  margin: 0 15px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
}

.news-sidebar .sidebar-item:last-child {
  border-bottom: 0;
}

.news-sidebar .sidebar-item img {
  width: 30%;
}

.news-sidebar .sidebar-item h4 {
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
}

/**/
.pagination {
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  width: 36px;
  height: 36px;
  display: block;
  text-align: center;
  line-height: 36px;
  border: 1px solid #ffbf4d;
  color: #ffbf4d;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.pagination li.next i,
.pagination li.prev i {
  position: relative;
  top: 10px;
}

.pagination li a.active,
.pagination li a:hover {
  background-color: #ffbf4d;
  color: #fff;
}

/**/
.style-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.style-checkbox label {
  position: relative;
  cursor: pointer;
}

.style-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}

.style-checkbox input:checked + label:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  display: block;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #28c76f;
  font-size: 13px;
}

.style-checkbox .false input:checked + label:after {
  content: '\f00d';
  color: #ea5455;
  left: 5px;
  font-size: 14px;
}

@media (max-width: 991px) {
  .bg-overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .menu-active .bg-overlay {
    display: block;
  }
}

@media (max-width: 768px) {
  .sm\:right-3 {
    right: 3px !important;
  }
}

/*loading*/
.loading-group {
}

@media (max-width: 768px) {
  .loading-group {
    width: 100%;
    order: 1;
    margin: 10px;
    justify-content: center;
  }
}

.loading-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  margin-right: 15px;
}

.loading-wrap {
  display: inline-block;
}

.loading {
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
  border: 2px solid #34c38f;
  border-radius: 99px;
  padding: 0 3px;
}

.loading-red {
  border-color: #f46a6a;
}

.loading div {
  display: block;
  left: 8px;
  width: 2px;
  height: 16px;
  margin: 2px 1.5px;
  background: #34c38f;
  animation: loading-anime 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loading-red div {
  background: #f46a6a;
}

.loading div:nth-child(1) {
  height: 14px;
  animation-delay: -1.56s;
}

.loading div:nth-child(2) {
  animation-delay: -1.44s;
}

.loading div:nth-child(3) {
  animation-delay: -1.32s;
}

.loading div:nth-child(4) {
  animation-delay: -1.2s;
}

.loading div:nth-child(5) {
  animation-delay: -1.18s;
}

.loading div:nth-child(6) {
  animation-delay: -1.06s;
}

.loading div:nth-child(7) {
  animation-delay: -0.94s;
}

.loading div:nth-child(8) {
  animation-delay: -0.82s;
}

.loading div:nth-child(9) {
  animation-delay: -0.7s;
}

.loading div:nth-child(10) {
  animation-delay: -0.68s;
}

.loading div:nth-child(11) {
  animation-delay: -0.56s;
}

.loading div:nth-child(12) {
  animation-delay: -0.48s;
}

.loading div:nth-child(13) {
  animation-delay: -0.36s;
}

.loading div:nth-child(14) {
  animation-delay: -0.24s;
}

.loading div:nth-child(15) {
  animation-delay: -0.12s;
}

.loading div:nth-child(16) {
  height: 14px;
  animation-delay: 0;
}

@keyframes loading-anime {
  0% {
    opacity: 0;
  }

  50%,
  100% {
    opacity: 1;
  }
}

/*essay-exam*/
.essay-exam {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
}

.essay-exam .item {
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 6px;
  background-color: #74788d;
  border: 1px solid #e7e7e7;
  margin: 5px;
  display: block;
  color: #fff;
  transition: all 0.4s ease;
  cursor: pointer;
}

.essay-exam .item:hover {
  background-color: #289cf9;
  color: #fff;
}

/*fill upload*/
.fill-upload .nav-pills {
  justify-content: center;
}

.fill-upload .nav-pills .nav-link {
  line-height: 1;
  border-radius: 99px;
  border: 1px solid #289cf9;
  background-color: #fff;
  color: #289cf9;
  padding: 12px 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px 0 rgba(40, 156, 249, 0.3);
  margin: 2px 7.5px 0;
}

.fill-upload .nav-pills .nav-link i {
  margin-right: 5px;
}

.fill-upload .nav-pills .nav-link:hover,
.fill-upload .nav-pills .nav-link.active {
  background-color: #289cf9;
  color: #fff;
}

@media (max-width: 768px) {
  .fill-upload .nav-pills .nav-item {
    width: 100%;
  }

  .fill-upload .nav-pills .nav-link {
    margin: 0 0 15px 0;
    width: 100%;
  }
}

/**/
/* .iw-upload .iw-uploader-selector {
    padding: 0;
    border: 0;
    border-radius: 0;
    overflow: unset;
    background-color: transparent;
}
.iw-upload .iw-upload-button {
    width: 115px;
    height: 115px;
    background-color: transparent;
    border: 1px dashed #43a9ff;
    box-shadow: none;
    border-radius: 10px;
    color: #43a9ff;
    margin-bottom: -115px;
    margin-left: 10px;
    margin-right: 10px;
}

.iw-upload .iw-button-icon {
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px 0 rgba(79, 174, 255, .7);
    position: absolute;
    border-radius: 99px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.iw-upload .iw-button-icon i {
    position: relative;
    top: 10px;
    font-size: 20px;
}

.iw-upload .iw-upload-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    overflow: unset;
}
.iw-upload .iw-upload-list .iw-upload-item {
    width: 115px;
    margin:0 10px 20px;
    height: 115px;
    border-radius: 10px;
}
.iw-upload .iw-upload-list .iw-upload-item:first-child {
    margin-left: 145px;
}

.iw-upload .iw-upload-list .iw-upload-item .iw-thumbnail-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.iw-upload .iw-upload-cancel {
    background-color: #f46a6a;
}
*/
/*upload file*/

/*
.iw-upload .iw-button-upload-file {
    height: 34px;
    width: 40%;
    border: 1px solid #d4ebfe;
    border-radius: 6px;
    padding: 10px 1px 10px 15px;
    display: flex;
    align-items: center;
}
@media (max-width: 560px) {
    .iw-upload .iw-button-upload-file {
        width: 80%;
    }
}
.iw-upload .iw-button-upload-file .iw-icon-plus {
    margin-left: auto;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 6px;
    background-color: #289cf9;
}
.iw-upload .iw-button-upload-file .iw-icon-plus i {
    position: relative;
    top: 8px;
}
.iw-upload .iw-upload-file-list {
    padding-top: 20px;
}
.iw-upload .iw-upload-cancel-file {
    border: 0;
    background-color: transparent;
    color: #289cf9;
    margin-left: 20px;
}
.iw-upload .iw-file-name {
    color: #289cf9;
    font-size: 14px;
}
.iw-upload .iw-file-name::before {
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f15c";
    margin-right: 5px;
} */
.ratio-1-1 {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.iw-upload-button {
  overflow: hidden;
  border-radius: 8px;
  border: 1px dashed #48abff;
  height: 100%;
}

.iw-upload-button label {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
}

.iw-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.iw-file-input {
  opacity: 0;
  padding: 5px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iw-btn-file-icon {
  display: inline-block;
  box-shadow: 0 0 10px 0 rgba(79, 174, 255, 0.7);
  border-radius: 99px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 18px;
  color: #43a9ff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.iw-btn-file-icon i {
  position: relative;
  top: 10px;
}

.iw-image-upload {
  position: relative;
}

.iw-image-upload-inner {
  overflow: hidden;
  border-radius: 8px;
}

.iw-image-upload img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.iw-image-upload-delete {
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 99px;
  background-color: #f46a6a;
  color: #fff;
  display: block;
  position: absolute;
  top: -5px;
  right: -5px;
}

.iw-image-upload-delete i {
  position: relative;
  top: 4px;
  font-size: 12px;
}

.iw-image-upload-delete:hover {
  background-color: #000;
  color: #fff;
}

.iw-upload-wrap {
  margin-left: -10px;
  margin-right: -10px;
}

.iw-upload-wrap .iw-upload-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.iw-upload-file-button::-webkit-file-upload-button {
  display: none;
}

.iw-upload-file-button-wrap {
  position: relative;
  width: 50%;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #d4ebfe;
  margin-top: 20px;
}

@media (max-width: 560px) {
  .iw-upload-file-button-wrap {
    width: 100%;
  }
}

.iw-upload-file-button {
  padding: 6px 35px 5px 15px;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
}

.iw-upload-file-button::before {
  content: '\f067';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 30px;
  width: 30px;
  border-radius: 8px;
  background-color: #289cf9;
  color: #fff;
  display: block;
  position: absolute;
  right: 1px;
  top: 1px;
  text-align: center;
  line-height: 30px;
}

.iw-upload-file-list {
  margin-top: 20px;
}

.iw-upload-file-list li {
  display: flex;
  align-items: center;
  color: #289cf9;
  margin-top: 10px;
}

.iw-upload-file-list li a {
  color: #289cf9;
}

.iw-upload-file-list li span {
  margin-left: 5px;
}

.iw-upload-file-action a {
  margin-left: 15px;
}

/*attach image*/
.line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iw-attach-gallery,
.iw-attach-image {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.iw-attach-gallery a,
.iw-attach-image a {
  padding: 10px;
  width: 16.6%;
}

@media (max-width: 560px) {
  .iw-attach-image {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .iw-attach-image a {
    width: 25%;
    padding: 2.5px;
  }
}

.iw-attach-file li {
  display: flex;
  align-items: center;
  color: #289cf9;
  margin: 5px 0;
}

.iw-attach-file li span {
  margin-left: 5px;
}

.iw-attach-file .iw-attach-file-action {
  display: flex;
}

.iw-attach-file .iw-attach-file-action a {
  margin-left: 15px;
  color: #289cf9;
}

/*sidebar question*/
.sidebar-question {
  padding: 15px;
  border-top: 1px solid #e7e7e7;
}

.sidebar-question label {
  display: block;
  font-size: 14px;
  color: #333;
}

.sidebar-question textarea {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #f3f2f7;
  padding: 15px;
}

.sidebar-question .sidebar-point {
  display: flex;
  align-items: center;
}

.sidebar-question .sidebar-point label {
  color: #43a9ff;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 30px;
}

.sidebar-question .sidebar-point input {
  width: 60px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

/* CKEDITOR */
.ck-editor__editable_inline {
  min-height: 200px;
}

.tree,
.tree ul,
.tree li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.tree {
  text-align: center;
}

.tree,
.tree ul {
  display: table;
}

.tree ul {
  width: 100%;
}

.tree li {
  display: table-cell;
  padding: 40px 8px;
  vertical-align: top;
}

.tree > li {
  padding-top: 0;
  padding-bottom: 0;
}

.tree ul li > ul li {
  padding-bottom: 0;
}

/* _________ */
.tree li:before {
  outline: solid 1px #cbcee8;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.tree li:first-child:before {
  left: 50%;
}

.tree li:last-child:before {
  right: 50%;
}

.tree .code,
.tree span {
  border: solid 1px #cbcee8;
  display: inline-block;
  margin: 0 0 40px;
  position: relative;
}

.tree ul:before,
.tree .code:before,
.tree span:before {
  outline: solid 1px #cbcee8;
  content: '';
  height: 40px;
  left: 50%;
  position: absolute;
}

.tree ul:before {
  top: -40px;
}

.tree .code:before,
.tree span:before {
  top: -40px;
}

.tree > li {
  margin-top: 0;
}

.tree > li:before,
.tree > li:after,
.tree > li > .code:before,
.tree > li > span:before {
  outline: none;
}

.tree-expert li ul > li {
  padding-bottom: 0;
}

.tree-expert li ul > li .code {
  margin-bottom: 30px;
}

.itable {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  font-size: 13px;
}

.itable tr th,
.itable tr td {
  border-bottom: 1px solid #cbcee8;
  padding: 12px 15px;
}

.itable tr th,
.itable tr td {
  border-right: 1px solid #cbcee8;
}

.itable tr th:first-child,
.itable tr td:first-child {
  border-left: 1px solid #cbcee8;
}

.itable tr th:last-child,
.itable tr td:last-child {
  border-right: 1px solid #cbcee8;
}

.itable tr th {
  border-top: solid 1px #cbcee8;
}

/* .itable tr td {
    background-color: #fff;
} */
.itable tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.itable tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.itable tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.itable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

@media (max-width: 1024px) {
  .itable {
    width: 1200px;
  }
}

/* Modal */
[id^='modal'] {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

[id^='modal']:target {
  display: block;
}

.popup {
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.popup__overlay {
  position: fixed;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000b3;
}

.popup__wrapper {
  position: fixed;
  z-index: 9;
  width: 80%;
  max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 58px 32px 32px 32px;
  background: #fff;
}

.popup__close {
  position: absolute;
  top: 16px;
  right: 26px;
}

/* Unline */
.underline {
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.paginationjs {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .paginationjs {
    text-align: center;
  }
}

.paginationjs .paginationjs-pages {
  float: none;
  display: inline-block;
}

.paginationjs ul {
  display: flex;
}

.paginationjs .paginationjs-pages li {
  border: 0;
}

.paginationjs .paginationjs-pages li.active > a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #089e60;
  color: #fff;
}

.paginationjs .paginationjs-pages li > a:hover {
  background-color: #089e60;
  color: #fff;
}

.paginationjs .paginationjs-go-input > input[type='text'],
.paginationjs .paginationjs-pages li > a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 8px;
  margin: 5px;
  border: 0;
}

.paginationjs .paginationjs-pages li.disabled > a:hover {
  background-color: #000;
  border-radius: 8px;
}

.paginationjs .paginationjs-pages li.disabled > a {
  opacity: 1;
}

.paginationjs .paginationjs-pages li:last-child {
  border: 0;
}

.paginationjs .paginationjs-pages li:first-child,
.paginationjs .paginationjs-pages li:first-child > a,
.paginationjs .paginationjs-pages li:last-child > a {
  border-radius: 8px;
}

.paginationjs .paginationjs-go-button > input[type='button'] {
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
}

.paginationjs .paginationjs-go-button {
  margin: 5px;
}

.paginationjs .paginationjs-go-input {
  margin: 0;
}

.fstElement {
  display: block;
  border: 1px solid #cbcee8;
  border-radius: 10px;
  font-size: 15px;
}

.fstMultipleMode .fstControls {
  width: auto;
  padding: 9px 25px 9px 15px;
}

.fstMultipleMode .fstQueryInput {
  font-size: 15px;
  margin: 0;
  color: #333;
}

.fstMultipleMode .fstQueryInputExpanded {
  padding: 0;
}

.fstNoResults,
.fstResultItem {
  padding: 9px 15px;
  font-size: 15px;
  color: #333;
}

.fstMultipleMode.fstActive .fstResults {
  height: 250px;
}

.fstMultipleMode .fstQueryInput::placeholder {
  color: #333;
}

[id^='dropdown'] {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

[id^='dropdown']:target {
  display: block;
}

.z-99999 {
  z-index: 99999;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

@media (max-width: 991px) {
  .popup-inner {
    width: 1000px;
  }
}

@media (max-width: 768px) {
  .popup-inner {
    width: 500px;
  }
}

.text-lineunder {
  text-decoration: underline;
}

.bg-ecf {
  background-color: #ecf4ff;
}

.bg-45a {
  background-color: #45aaf2;
}

.select-no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.bd-none {
  border: none;
}

/* Tooltip */
[tooltip] {
  position: relative;
}

[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

[tooltip]::after {
  content: attr(tooltip);
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  z-index: 1000;
  border-radius: 6px;
  font-size: 12px;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
  opacity: 1;
}

[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

[tooltip][flow^='down']::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}

[tooltip][flow^='down']::after {
  top: calc(100% + 5px);
}

[tooltip][flow^='down']::before,
[tooltip][flow^='down']::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

@media (max-width: 1024px) {
  .overflow-wrap {
    overflow-x: auto;
  }
}
.cursor {
  cursor: pointer;
}

/* @media (min-width: 280px) {
    .ant-col-md-24 {
        display: flex !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        justify-content: start !important;
    }
  }
  @media (min-width: 1105px) {
    .ant-col-lg-12 {
        display: flex !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
        justify-content: end !important;
    }
  }

  @media (min-width: 1200px){   
    .ant-col-lg-12 {
        display: flex !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        justify-content: start !important;
    }
}

@media (min-width: 1383px){   
    .ant-col-lg-12 {
        display: flex !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;  
        justify-content: end !important;
    }
} */

.txtreport {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 400;
  cursor: pointer;
}
.txtreport:hover {
  font-weight: 500;
}
.item1 {
  grid-column: none;
}
