.school-info {
    background-color: rgb(145, 213, 255);
    margin-right: 10px;
    margin-top: 28px;
    padding: 10px;
    height: 300px;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.school-info .school-info-row span:first-child {
    margin-right: 10px;
    width: 42%;
}

.title {
    border-bottom: 1px solid rgba(0,0,0,0.25);
    padding-bottom: 10px;
    margin-bottom: 10px;
}